import React from 'react'

/* eslint-disable */

const noGtag = (...args) => {
  console.warn("gtag not loaded", args);
};

const gtag = typeof window !== "undefined" ? window.gtag || noGtag : noGtag;

const GAID = process.env.GA || "0";

gtag("config", GAID);

export function trackPageView(path) {
  gtag("config", GAID, { page_path: path });
}

export function trackEvent(action, category, label, value = null) {
  gtag("event", action, {
    event_category: category,
    event_label: label,
    value: value
  });
}

class TrackPageChange extends React.Component {
  componentDidMount() {
    const page = this.props.location.pathname;
    trackPageView(page);
  }

  componentDidUpdate(prevProps) {
    const currentPage = this.props.location.pathname;
    const prevPage = prevProps.location.pathname;

    if (currentPage !== prevPage) {
      trackPageView(currentPage);
    }
  }

  render() {
    return <div />;
  }
}

export { TrackPageChange };
