import cls from 'classnames'
import { Link } from 'gatsby'
import propTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet'
import scrollToElement from 'scroll-to-element'
import { trackEvent } from '../../utils/Tracking'
import Button from '../button/Button'
import HTMLContent from '../htmlContent/HTMLContent'
import './CareerItem.scss'

export default class CareerItem extends React.Component {
  static propTypes = {
    data: propTypes.any,
    to: propTypes.string,
    location: propTypes.string,
    open: propTypes.bool,
    onClick: propTypes.func
  };
  ref = null;
  componentDidMount() {
    if (this.props.open) {
      this.scrollTo()
    }
  }
  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.scrollTo()
    }
  }

  scrollTo = () => {
    scrollToElement(this.ref, { duration: 1000, offset: -140 })
  };

  trackApplyClick(name) {
    trackEvent('apply', 'career', name)
  }

  renderDetails = (data, location) => {
    const email = data.email
    return (
      <div className='CareerItem__details'>
        <Helmet>
          <title>Blue Harvest - Careers - {data.name}</title>
        </Helmet>

        <HTMLContent content={data._rawContent} />

        {email && (
          <div className='CareerItem__button-wrap text-right'>
            <Button
              onClick={() => this.trackApplyClick(data.name)}
              target='_blank'
              href={`mailto:${email}?subject=${location + ' - ' + data.name}`}
              label='Apply now'
            />
          </div>
        )}
      </div>
    )
  };

  setRef = ref => {
    this.ref = ref
  };

  render() {
    const { data, open, to, location } = this.props
    return (
      <div ref={this.setRef} className={cls('CareerItem', { 'is-open': open })}>
        <Link
          to={to}
          onClick={this.props.onClick}
          className={cls('CareerItem__btn', {
            'size-20': !open,
            'size-40': open
          })}
        >
          <div className='CareerItem__btn-title '>{data.name}</div>
          {open && <i className='fas fa-minus' />}
          {!open && <i className='fas fa-plus' />}
        </Link>
        {open && this.renderDetails(data, location)}
      </div>
    )
  }
}
