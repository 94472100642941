import React from 'react'
import Template from '../template/Template'
import CareersPage from '../pageComponents/careers/CareersPage'

export default props => {
  return (
    <Template key='careers'>
      <CareersPage key='careers' {...props} />
    </Template>
  )
}
