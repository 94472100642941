import React, { Component } from 'react'
import { urlize } from 'urlize'
import CareerItem from '../../components/careerItem/CareerItem'
import Footer from '../../components/footer/Footer'
import Triangle from '../../components/triangle/Triangle'
import './CareersPage.scss'
import { StaticQuery, graphql } from 'gatsby'
import groupBy from 'lodash/groupBy'
import LifeAtBH from './lifeAtBH'
import SEO from '../../components/seo/Seo'
import DynamicText from '../../components/dynamicText/DynamicText'
import InnerHTML from 'dangerously-set-html-content'

class CareersPage extends Component {
  renderLocations = (data) => {
    return Object.keys(data).map((cityName) => {
      return (
        <div key={cityName}>
          <div className='CareersPage__list'>
            <div className='CareersPage__location size-34 embed-title text-center bold'>
              {cityName}
            </div>
            {this.renderList(data[cityName], cityName)}
          </div>
        </div>
      )
    })
  }
  renderList = (data, location) => {
    const urlLocation = urlize(location)

    return data.map((itemData, i) => {
      const urlTitle = urlize(itemData.name)

      const isOpen = this.props.pathContext.selectedCareerId === itemData.id

      return (
        <CareerItem
          to={isOpen ? `/careers/` : `/careers/${urlLocation}/${urlTitle}/`}
          open={isOpen}
          key={i}
          data={itemData}
          location={location}
        />
      )
    })
  }
  recruitee = () => {
    const payload = {
      companies: [56538],
      detailsMode: 'popup',
      language: 'en',
      departmentsFilter: [],
      themeVars: {
        theme: 'dark',
        primary: '#059296',
        secondary: '#000',
        text: '#059296',
        textDark: '#fff',
        fontFamily: '"Montserrat", sans-serif;',
        baseFontSize: '16px'
      },
      flags: {
        showLocation: true,
        showCountry: true,
        showCity: true,
        groupByLocation: true,
        groupByDepartment: false,
        groupByCompany: false
      }
    }

    const widget = `
    <div id="recruitee-careers"></div>
    <script type="text/javascript">
      var widget = new RTWidget(${JSON.stringify(payload)});
          </script>`
    return <InnerHTML html={widget} />
  }
  render() {
    return (
      <div>
        <SEO id='careers' />
        <div className='CareersPage'>
          <Triangle position='right' />
          <div className='CareersPage__wrap'>
            <div className='container'>
              <div className='size-14 CareersPage__sub-title semi-bold text-center'>
                JOIN US
              </div>
              <div className='size-54 CareersPage__title embed-title text-center bold'>
                CAREERS
              </div>

              <div className='dynamic-careersIntro'>
                <DynamicText text='_rawCareersIntro' />
              </div>

              {this.recruitee()}
              {/* {this.renderLocations(this.props.data)} */}
            </div>
          </div>
          <Triangle position='right' />
          <div className='container'>
            <div className='size-14 TechRadarPage__sub-title color-blue semi-bold text-center'>
              WHAT WE'RE LIKE
            </div>
            <LifeAtBH />
          </div>

          <Triangle position='left' />
        </div>
        <Footer />
      </div>
    )
  }
}

const query = graphql`
  query allSanityCareer {
    allSanityCareer {
      edges {
        node {
          id
          name
          email
        }
      }
    }
  }
`

const prepareData = (data) => {
  const nodes = data.allSanityCareer.edges.map((v) => v.node)
  const grouped = groupBy(nodes, 'name')
  return grouped
}

export default (props) => {
  return (
    <StaticQuery
      key='careers'
      query={query}
      render={(data) => {
        return <CareersPage key='careers' {...props} data={prepareData(data)} />
      }}
    />
  )
}
